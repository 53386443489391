import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { ClerkLoading, SignIn, useClerk } from '@clerk/nextjs'
import { createParam } from 'solito'
import {
  DEFAULT_LOGIN_REDIRECT_URL,
  LOGIN_PATH,
  REDIRECT_URL_KEY,
  SIGNUP_PATH,
} from '@purposity/auth'
import { makeLog } from '@purposity/utils'
import { ActivityFooter } from '@components/Fillers/ActivityFooter'
import { useReferral } from '@features/referral/useReferral'
import { clerkAppearanceOptions } from '@themes/ClerkThemeOptions'
import * as Colors from '@themes/Colors'

const { useParam } = createParam<{ [REDIRECT_URL_KEY]?: string }>()

export default function ClerkLoginPage() {
  const [urlFromParam] = useParam(REDIRECT_URL_KEY, {
    initial: undefined,
    parse: (param) => {
      return Array.isArray(param) ? param[0] : param
    },
  })

  const { client } = useClerk()

  const { referral } = useReferral()

  const afterSignInUrl = referral?.redirectUrl
    ? `${referral.redirectUrl}?src=referral`
    : urlFromParam
    ? `${urlFromParam}?src=param`
    : DEFAULT_LOGIN_REDIRECT_URL

  const loginRef = client?.signIn
  React.useEffect(() => {
    const logger = makeLog(`effect2`)
    if (typeof window !== 'undefined') {
      if (loginRef) {
        if (loginRef.id) {
          logger.log('signUp id', loginRef.id)
          // loginRef.update({
          //   unsafeMetadata: {
          //     referral: referral?.data,
          //   },
          //   redirectUrl: afterSignInUrl,
          // })
        } else {
          logger.log('no signUp id, creating one')

          // loginRef.create({
          //   unsafeMetadata: {
          //     referral: referral?.data,
          //   },
          //   redirectUrl: afterSignInUrl,
          // })
        }
      }
    }
  }, [loginRef, referral?.data, afterSignInUrl])

  return (
    <View
      style={{
        ...StyleSheet.absoluteFillObject,
        backgroundColor: Colors.backgroundGray,
      }}
    >
      <View style={{ flexGrow: 1, flexShrink: 1, flexBasis: '0%' }}>
        <View style={{ flexGrow: 1 - Math.sqrt(5) / 2 }} />
        <ClerkLoading>
          <ActivityFooter active />
        </ClerkLoading>

        <SignIn
          routing="path"
          path={LOGIN_PATH}
          signUpUrl={SIGNUP_PATH}
          afterSignInUrl={afterSignInUrl}
          appearance={clerkAppearanceOptions}
        />
      </View>
    </View>
  )
}
